.tag {
  display: inline-block;
  padding: 2px 6px;
  color: $white;
  font-size: 0;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  outline: 0;
  &-text {
    font-size: 11px;
    line-height: 13px;
  }
  &-close {
    font-size: 9px;
    line-height: 13px;
    margin: 0;
    margin-left: 3px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background: rgba(243, 243, 243, 0.4);
    }
    &:focus {
      outline-style: dotted;
      outline-width: 1px;
      outline-offset: 1px;
    }
  }
}
@each $color, $value in $theme-colors {
  .tag-#{$color} {
    @include badge-variant($value);
  }
}
