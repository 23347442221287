$loading-bar-color: #ffffff !default;
.loading-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}

.progress-line, .progress-line:before {
  height: 2px;
  width: 100%;
  margin: 0;
}
.progress-line {
  background-color: lighten($loading-bar-color, 35%);
  display: -webkit-flex;
  display: flex;
}

.progress-line:before {
  background-color: lighten($loading-bar-color, 1%);
  content: '';
  -webkit-animation: running-progress 1.25s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation: running-progress 1.25s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@-webkit-keyframes running-progress {
  0% { margin-left: 0px; margin-right: 100%; }
  50% { margin-left: 25%; margin-right: 0%; }
  100% { margin-left: 100%; margin-right: 0; }
}

@keyframes running-progress {
  0% { margin-left: 0px; margin-right: 100%; }
  50% { margin-left: 25%; margin-right: 0%; }
  100% { margin-left: 100%; margin-right: 0; }
}