// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '../../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../../node_modules/@fortawesome/fontawesome-free/scss/brands';
@import '../../../../node_modules/@fortawesome/fontawesome-free/scss/regular';
@import '../../../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../../../node_modules/animate-sass/animate";
@import '../../../../node_modules/sweetalert2/src/sweetalert2';
@import "../../../../node_modules/vue-awesome-notifications/dist/styles/style.css";
@import "../../../../node_modules/animate-sass/animate";
@import "../../../../node_modules/vue-select/src/scss/vue-select";
@import "../../../../node_modules/pc-bootstrap4-datetimepicker/src/sass/bootstrap-datetimepicker-build.css";
@import "../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@import "components/form";
@import "components/loading-bar";
@import "components/tags";
@import "components/filterable";
.app-main-section{
    margin-top: 60px;
}
.invalid-feedback{
    display: block;
}
.dashboard {
    margin-top: 80px;
    .card{
        border: none;
        .card-title{
            border-bottom: 1px solid #c9c9c9;
        }
        .card-body{
            a{
                &.dash-link{
                    display: block;
                }
            }
        }
    }
}
.required {
    label {
        &::after{
            content: "*";
            padding-left: 2px;
            color: $red;

        }
    }
}
td{
    &.actions {
        min-width: 150px;
    }
}
.sorting {
    a {
        &.asc {
            &::after {
                font-family: $table-header-sorting-icon-font;
                content: $table-header-sorting-icon-asc;
                margin-left: 3px;
                font-weight: 900;
            }
        }

        &.desc {
            &::after {
                font-family: $table-header-sorting-icon-font;
                content: $table-header-sorting-icon-desc;
                margin-left: 3px;
                font-weight: 900;
            }
        }
    }
}
.settings-sidebar {
    .nav-pills {
        .nav-link {
            &.router-link-active{
                color: $nav-pills-link-active-color;
                background-color: $nav-pills-link-active-bg;
            }
        }
        .show {
            .nav-link {
                color: $nav-pills-link-active-color;
                background-color: $nav-pills-link-active-bg;
            }
        }
    }
}
/* Top Tab */
.filter-nav {
    li {
        &.d-table {
            display: inherit !important;
        }
        a.nav-link {
            color: #222529;

            &.active {
                background: transparent;
                color: #222529;
                border-bottom: 2px solid $green;
                border-radius: 0;
            }

            &.router-link-exact-active {
                background: transparent;
                color: #222529;
                border-bottom: 2px solid $green;
                border-radius: 0;
            }

            &.router-link-active {
                background: transparent;
                color: #222529;
                border-bottom: 2px solid $green;
                border-radius: 0;
            }
        }
    }

    .filter-nav-li-active {
        .router-link-exact-active {
            border-bottom: 2px solid $green;
        }
    }

    .sorting {
        a {
            font-size: 13px;
            cursor: pointer;

            &.active, &:active {
                background-color: $third-color;
            }

            &.desc {
                &::after {
                    left: 8px;
                    position: absolute;
                }
            }

            &.asc {
                &::after {
                    left: 8px;
                    position: absolute;
                }
            }
        }
    }
}

.nav-tabs .nav-link{color:$green;}
.gmap-search-bar{
    background: #fbfcfc;
    padding-left: 15px;
    font-size: 20px;    
    color: #8d8d8d;
    height: 58px;
    border: 1px solid #c4c4c4;
    border-radius: 0;
    height:45px;
    font-size:15px;
}
.address-book-container{
    box-shadow:0 0 49px #37784730;
    border-radius:10px;
    position: relative;
    margin:20px 0 50px 0;
}
.page-item.active .page-link{background-color:$green;border-color:$green;}